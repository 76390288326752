<template>
    <div class="search">
      <div class="header">
        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M15.5 15.5L19 19" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M5 11C5 14.3137 7.68629 17 11 17C12.6597 17 14.1621 16.3261 15.2483 15.237C16.3308 14.1517 17 12.654 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
        <input ref="input" placeholder="Search For Anything" v-on:input="searching" />
      </div>

      <div class="results" v-if="!loading">
        <div v-for="(r, i) in results" :key="i">

          <div v-if="r.results.length > 0">
            <h3 v-if="r.title" >{{ r.title }}
              <router-link :to="r.link" v-ripple>
                see all 
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"> <path d="M8 21H20.4C20.7314 21 21 20.7314 21 20.4V3.6C21 3.26863 20.7314 3 20.4 3H3.6C3.26863 3 3 3.26863 3 3.6V16" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M10 6L18 6" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M6 6H7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M3.5 20.5L12 12M12 12V16M12 12H8" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
              </router-link>
            </h3>

            <div class="result-items content-row" :class="r.type">
              <template v-for="(k,j) in r.results">

                <ListGuide :guide="k" :key="j"  v-if="r.type === 'epg'" v-ripple/>

                <Channel v-if="r.type === 'channel'" :channel="k"  :key="j" v-ripple/>

                <Game :game="k" size="wide" v-if="r.type === 'games'"  :key="j" v-ripple/>

                <Sport v-if="r.type === 'sport'" :sport="k" mode="list"  :key="j" v-ripple/>

                <Movie v-if="r.type === 'vod'" :media="k" :display="true" :list="true"  :key="j"  v-ripple/>
              </template>
            </div>

          </div>

        </div>
      </div>

    </div>
</template>

<script>
const ListGuide = () => import('@/components/ListGuide')
const Game = () => import('@/components/Game')
const Channel = () => import('@/components/Channel')
const Sport = () => import('@/components/Sport')
const Movie = () => import('@/components/Movie')

export default {
  name: 'Search',

  components: {
    Channel,
    ListGuide,
    Game,
    Sport,
    Movie,
},

  computed: {
  },

  data: function(){
    return {
        query: '',
        results: [],
        loading: true,
    }
  },

watch: { 
  },

  methods: {
    debounce(func, timeout = 300){
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
      };
    },

    searching: function(){
      this.debounce(this._searching, 500)()
    },

    _searching(){
      this.query = this.$refs.input.value
      this.getSearchResults() 
    },
   
    getSearchResults: async function(){
        this.loading = true
        this.results = []
        const data = await fetch(`${process.env.VUE_APP_SERVER}/search?query=${this.query}`)
        const results = await data.json()
        this.results = results.results
        this.loading = false
    },

   
  },

  mounted: function(){
      this.query = window.shuffle('ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''))[0]
      this.getSearchResults()
      this.query = ''
      this.$nextTick(() => { this.$refs.input.focus() })
  }

}
</script>

<style scoped lang="scss">

.search {
  padding-bottom: 20px;
  .header{

    padding: 20px;
    padding-bottom: 0px;
    input{
      height: 30px;
      background-color: transparent;
      border: none;
      width: 100%;
      color: white;
      outline: none;
      border-bottom: 1px solid white;

    }
  }

  div h3{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a{
      font-size: 11px;
      font-weight: normal;
      color: white;
      display: flex;
      align-items: center;
      svg{
        padding-left: 10px;
        width: 12px;
        height: 12px;
      }
    }
  }
  .games .game{
    width: 100%;
    margin-right: 0;
  }

  .wide{
    margin-right: 10px!important;

  }
  .channel, .sport, .vod{
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    padding: 0 20px;
    &.sport{
      > a {
        width: 100%;
        padding-right: 10px;
      }
    }
    > div{
      margin-right: 10px;
    }

  }

}

</style>
